import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/80/components/shared/Cookies/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/80/components/shared/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/80/components/shared/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/80/components/shared/providers/react-query.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/80/components/shared/utilities/YandexMetrika/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/80/node_modules/modern-normalize/modern-normalize.css");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/80/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/80/styles/index.sass");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/80/node_modules/next/font/local/target.css?{\"path\":\"lib/fonts/ProximaNova.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Light.ttf\",\"weight\":\"300\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Light.eot\",\"weight\":\"300\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Light.woff\",\"weight\":\"300\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Regular.ttf\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Regular.eot\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Regular.woff\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Semibold.ttf\",\"weight\":\"600\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Semibold.eot\",\"weight\":\"600\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Semibold.woff\",\"weight\":\"600\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Bold.ttf\",\"weight\":\"700\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Bold.eot\",\"weight\":\"700\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Bold.woff\",\"weight\":\"700\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Bold.woff2\",\"weight\":\"700\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Extrabld.ttf\",\"weight\":\"800\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Extrabld.eot\",\"weight\":\"800\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Extrabld.woff\",\"weight\":\"800\"}],\"variable\":\"--font-proximaNova\"}],\"variableName\":\"proximaNova\"}");
